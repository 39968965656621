<template>
    <v-card style="position: relative;">
        <v-card-title class="light--text"
            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
            style="position: sticky; top: 0; width: 100%; z-index: 1000;"
        >
            <span ref="formTitle" class="headline">{{ `${event ? event.text + ' - ' : ''}${clickedRow.account_group_description}` }}</span>

            <v-spacer></v-spacer>

            <v-btn id="insertNewRowBtn"
                dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                @click="insertNewRow()"
                :disabled="loading || readOnly"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                @click="deleteSelectedRow()"
                :disabled="loading || disableDelete || !!editedRows.length"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-btn dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                :disabled="loading"
                @click="getData()"
            >
                <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                @click="confirmChartAccountInputDialog()"
                :disabled="loading || disableSave || !editedRows.length"
            >
                <v-icon>{{ editedRows.length ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
            </v-btn>

            <v-btn dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                @click="importSheet()"
                :loading="importing"
                :disabled="loading || readOnly"
            >
                <v-icon>mdi-file-excel</v-icon>
            </v-btn>
            <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="onFileChanged"
            >

            <v-btn dark text class="mb-2 mr-4 pa-0"
                min-width="48px"
                @click="exportSheet()"
                :loading="exporting"
                :disabled="loading"
            >
                <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn dark text class="mb-2 pa-0"
                min-width="48px"
                @click="closeChartAccountInputDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container fluid class="pa-0 ma-0">
                <v-row no-gutters class="d-flex justify-start align-center mt-6">
                    <v-col class="d-flex justify-start">
                        <v-text-field
                            name="event"
                            label="Evento"
                            class="pa-0 mr-4"
                            style="max-width: 300px !important; z-index: 1000 !important;"
                            :value="event.text"
                            readonly
                        />

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                        <div class="d-flex mt-1">
                            <v-checkbox
                                label="Mês"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="year1month"
                                @change="showAllMonths(1)"
                            />

                            <!-- <v-menu
                                bottom
                                left
                                :close-on-content-click="false"
                                style="z-index: 1000 !important;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        class="mr-4 pt-1"
                                        :disabled="!year1month"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet class="px-4 py-2" max-width="260px">
                                    <v-select
                                        :value="year1months"
                                        :items="months"
                                        label="Escolha os meses"
                                        :item-text="item => item.text"
                                        @change="showMonth(1)"
                                        multiple
                                        return-object
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.text }}</span>

                                            <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                            <template v-if="index === 2">
                                                <span>...</span>
                                                <span class="grey--text text-caption pa-1">
                                                    {{ `+ ${year1months.length - 2}` }}
                                                </span>
                                            </template>

                                        </template>
                                    </v-select>

                                    <div class="d-flex justify-end">
                                        <v-btn small color="primary" class="ma-0 mb-2"
                                            @click="year1months = months, showAllMonths(1)"
                                            :disabled="year1months.length === 12"
                                        >
                                            Selecionar todos
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-menu> -->

                            <v-checkbox
                                label="Tri"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="year1quarter"
                                @change="showAllQuarters(1)"
                            />

                            <!-- <v-menu
                                bottom
                                left
                                :close-on-content-click="false"
                                style="z-index: 1000 !important;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        class="mr-4 pt-1"
                                        :disabled="!year1quarter"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet class="px-4 py-2" max-width="240px">
                                    <v-select
                                        v-model="year1quarters"
                                        :items="quarters"
                                        label="Escolha os trimestres"
                                        :item-text="item => item.text"
                                        @change="showQuarter(1)"
                                        multiple
                                        return-object
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.text }}</span>

                                            <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                            <template v-if="index === 2">
                                                <span>...</span>
                                                <span class="grey--text text-caption pa-1">
                                                    {{ `+ ${year1quarters.length - 2}` }}
                                                </span>
                                            </template>

                                        </template>
                                    </v-select>

                                    <div class="d-flex justify-end">
                                        <v-btn small color="primary" class="ma-0 mb-2"
                                            @click="year1quarters = quarters, showAllQuarters(1)"
                                            :disabled="year1quarters.length === 4"
                                        >
                                            Selecionar todos
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-menu> -->

                            <v-checkbox
                                label="Sem"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="year1semester"
                                @change="showAllSemesters(1)"
                            />

                            <!-- <v-menu
                                bottom
                                left
                                :close-on-content-click="false"
                                style="z-index: 1000 !important;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        class="mr-4 pt-1"
                                        :disabled="!year1semester"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet class="px-4 py-2" max-width="240px">
                                    <v-select
                                        v-model="year1semesters"
                                        :items="semesters"
                                        label="Escolha os semestres"
                                        :item-text="item => item.text"
                                        @change="showSemester(1)"
                                        multiple
                                        return-object
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.text }}</span>

                                            <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                            <template v-if="index === 2">
                                                <span>...</span>
                                                <span class="grey--text text-caption pa-1">
                                                    {{ `+ ${year1semesters.length - 2}` }}
                                                </span>
                                            </template>

                                        </template>
                                    </v-select>

                                    <div class="d-flex justify-end">
                                        <v-btn small color="primary" class="ma-0 mb-2"
                                            @click="year1semesters = semesters, showAllSemesters(1)"
                                            :disabled="year1semesters.length === 2"
                                        >
                                            Selecionar todos
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-menu> -->

                            <v-checkbox
                                label="Ano 1"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="startingYear"
                                @change="showYear(1)"
                            />
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                        <div class="d-flex mt-1">
                            <v-checkbox
                                label="Mês"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="year2month"
                                @change="showAllMonths(2)"
                            />

                            <!-- <v-menu
                                bottom
                                left
                                :close-on-content-click="false"
                                style="z-index: 1000 !important;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        class="mr-4 pt-1"
                                        :disabled="!year2month"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet class="px-4 py-2" max-width="240px">
                                    <v-select
                                        :value="year2months"
                                        :items="months"
                                        :item-text="item => item.text"
                                        @change="showMonth(2)"
                                        label="Escolha os meses"
                                        multiple
                                        return-object
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.text }}</span>

                                            <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                            <template v-if="index === 2">
                                                <span>...</span>
                                                <span class="grey--text text-caption pa-1">
                                                    {{ `+ ${year2months.length - 2}` }}
                                                </span>
                                            </template>

                                        </template>
                                    </v-select>

                                    <div class="d-flex justify-end">
                                        <v-btn small color="primary" class="ma-0 mb-2"
                                            @click="year2months = months, showAllMonths(2)"
                                            :disabled="year2months.length === 12"
                                        >
                                            Selecionar todos
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-menu> -->

                            <v-checkbox
                                label="Tri"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="year2quarter"
                                @change="showAllQuarters(2)"
                            />

                            <!-- <v-menu
                                bottom
                                left
                                :close-on-content-click="false"
                                style="z-index: 1000 !important;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        class="mr-4 pt-1"
                                        :disabled="!year2quarter"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet class="px-4 py-2" max-width="240px">
                                    <v-select
                                        v-model="year2quarters"
                                        :items="quarters"
                                        label="Escolha os trimestres"
                                        :item-text="item => item.text"
                                        @change="showQuarter(2)"
                                        multiple
                                        return-object
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.text }}</span>

                                            <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                            <template v-if="index === 2">
                                                <span>...</span>
                                                <span class="grey--text text-caption pa-1">
                                                    {{ `+ ${year2quarters.length - 2}` }}
                                                </span>
                                            </template>

                                        </template>
                                    </v-select>

                                    <div class="d-flex justify-end">
                                        <v-btn small color="primary" class="ma-0 mb-2"
                                            @click="year2quarters = quarters, showAllQuarters(2)"
                                            :disabled="year2quarters.length === 4"

                                        >
                                            Selecionar todos
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-menu> -->

                            <v-checkbox
                                label="Sem"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="year2semester"
                                @change="showAllSemesters(2)"
                            />

                            <!-- <v-menu
                                bottom
                                left
                                :close-on-content-click="false"
                                style="z-index: 1000 !important;"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        class="mr-4 pt-1"
                                        :disabled="!year2semester"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-sheet class="px-4 py-2" max-width="240px">
                                    <v-select
                                        v-model="year2semesters"
                                        :items="semesters"
                                        label="Escolha os semestres"
                                        :item-text="item => item.text"
                                        @change="showSemester(2)"
                                        multiple
                                        return-object
                                    >
                                        <template v-slot:selection="{ item, index }">
                                            <span v-if="index === 0">{{ item.text }}</span>

                                            <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                            <template v-if="index === 2">
                                                <span>...</span>
                                                <span class="grey--text text-caption pa-1">
                                                    {{ `+ ${year2semesters.length - 2}` }}
                                                </span>
                                            </template>

                                        </template>
                                    </v-select>

                                    <div class="d-flex justify-end">
                                        <v-btn small color="primary" class="ma-0 mb-2"
                                            @click="year2semesters = semesters, showAllSemesters(2)"
                                            :disabled="year2semesters.length === 2"
                                        >
                                            Selecionar todos
                                        </v-btn>
                                    </div>
                                </v-sheet>
                            </v-menu> -->

                            <v-checkbox
                                label="Ano 2"
                                :ripple="false"
                                class="pa-0 ma-0 mr-4"
                                :input-value="laterYear"
                                @change="showYear(2)"
                            />
                        </div>
                    </v-col>
                </v-row>

                <v-row no-gutters class="d-flex justify-start align-center mt-2">
                    <v-autocomplete
                        :name="purposeOption.column"
                        :label="purposeOption.label"
                        :items="purpose"
                        v-model="selectedPurpose"
                        style="max-width: 300px !important; z-index: 1000 !important;"
                        class="pa-0 mr-4"
                    />

                    <v-autocomplete
                        :name="costCenterOption.column"
                        :label="costCenterOption.label"
                        :items="costCenter"
                        v-model="selectedCostCenter"
                        style="max-width: 300px !important; z-index: 1000 !important;"
                        class="pa-0 mr-4"
                    />
                </v-row>

                <v-row v-if="loading" class="mt-4">
                    <v-col class="d-flex justify-center align-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            small
                        ></v-progress-circular>
                    </v-col>
                </v-row>

                <v-row v-else no-gutters>
                    <v-col class="pa-0">
                        <v-sheet class="rounded-b pa-0 ma-0">
                            <HotTable ref="hotTableChartAccountInput"
                                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                                :data="objectData"
                                :settings="settings"
                                :colHeaders="colHeaders"
                                :columns="columnsDialog"
                                :hiddenColumns="hiddenColumns"
                                :rowHeaders="true"
                                :contextMenu="contextMenu"
                                :dropdownMenu="dropDownMenu"
                                :manualColumnResize="true"
                                :multiColumnSorting="true"
                                :bindRowsWithHeaders="true"
                                width="100%"
                                :height="windowSize.y - 64 - 250"
                                :filters="true"
                                :comments="true"
                                :customBorders="true"
                                :columnHeaderHeight="50"
                                :rowHeaderWidth="40"
                                :trimWhitespace="false"
                                :copyPaste="true"
                                fixedColumnsLeft="2"
                                stretchH="last"
                                :renderAllRows="false"
                            />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'

export default {
    name: 'ZnapChartAccountInputDialog',

    components: { HotTable },

    props: {
        dialogChartAccountInput: { type: Boolean, required: true },
        clickedRow: { type: Object, required: true },
        event: { type: Object, required: true },
        readOnly: { type: Boolean, required: false, default: false },
        purpose: { type: Array, required: true },
        purposeOption: { type: Object, required: true },
        costCenter: { type: Array, required: true },
        costCenterOption: { type: Object, required: true },
        startingYear: { type: Boolean, required: true },
        year1month: { type: Boolean, required: true },
        year1months: { type: Array, required: true },
        year1quarter: { type: Boolean, required: true },
        year1quarters: { type: Array, required: true },
        year1semester: { type: Boolean, required: true },
        year1semesters: { type: Array, required: true },
        laterYear: { type: Boolean, required: true },
        year2month: { type: Boolean, required: true },
        year2months: { type: Array, required: true },
        year2quarter: { type: Boolean, required: true },
        year2quarters: { type: Array, required: true },
        year2semester: { type: Boolean, required: true },
        year2semesters: { type: Array, required: true },
        year1: { type: Number, required: true },
        year2: { type: Number, required: true },
        startingMonthIndex: { type: Number, required: true },
        fiscalYearStartMonth: { type: Number, required: true },
        windowSize: { type: Object, required: true }
    },

    data() {
        return {
            objectData: [],
            settings: {
                language: ptBR.languageCode,
                formulas: {
                    engine: HyperFormula,
                },
                afterSelection: () => {
                    this.selectedRow = this.hotInstance.getSelected()
                },
                cells: (row, col, prop) => {
                    var cellProperties = {}

                    if (row === this.objectData.length - 1) {
                        cellProperties.className += ' font-bold htRight'
                        if (col <= 2) {
                            cellProperties.readOnly = true
                            cellProperties.type = 'text'
                        } else {
                            cellProperties.readOnly = true
                            cellProperties.type = 'numeric'
                            cellProperties.numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    const monthInputStart = parseInt((this.event.year_month_input_start).toString().slice(4, 6))
                    const difference = monthInputStart - this.fiscalYearStartMonth

                    if (difference > 0) {
                        for (let i = 1; i <= difference; i++) {
                            let colProp

                            if (i < 10) {
                                colProp = `value0${i}`
                            } else {
                                colProp = `value${i}`
                            }

                            if (colProp === prop) {
                                cellProperties.readOnly = true
                                cellProperties.className += ` read-only-column`
                            }
                        }
                    }

                    const totalColumns = [
                        'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                        'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                    ]
                        
                    if (totalColumns.includes(prop)) {
                        cellProperties.className += ' border-sides-1 font-bold'
                    }

                    if (this.readOnly) {
                        cellProperties.readOnly = this.readOnly
                    }

                    return cellProperties
                },
                afterChange: (changes) => {
                    if (changes) {
                        changes.forEach(change => {
                            let row = change[0]
                            let col = change[1]
                            let oldVal = change[2]
                            let newVal = change[3]
                            let physicalRow = this.hotInstance.toPhysicalRow(row)
                            
                            if (newVal === null) {
                                this.objectData[physicalRow].account_code = null
                            }

                            if (oldVal !== newVal) {
                                if (col === 'chart_account_description') {
                                    let found = this.chartAccounts.find(chartAccount => chartAccount.text === newVal)
                                    if (found) {
                                        this.objectData[physicalRow].account_code = found.account_code
                                        this.objectData[physicalRow].id_chart_account = found.id_chart_account
                                    }
                                }

                                if (!this.editedRows.includes(physicalRow)) {
                                    this.editedRows.push(physicalRow)
                                }
                            }
                        })

                        this.hotInstance.render()
                    }
                },
                // fillHandle: {
                //     autoInserRow: false
                // },
            },
            contextMenu: [
                'copy',
                // 'hidden_rows_hide',
                // 'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],
            hotInstance: null,

            data: [],
            headers: [],
            colHeaders: [],
            columns: [],
            columnsDialog: [],
            chartAccounts: null,
            selectedRow: null,
            editedRows: [],
            selectedPurpose: {},
            selectedCostCenter: {},

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            // startingYear: true,
            // year1month: true,
            // year1months: [],
            // year1quarter: false,
            // year1quarters: [],
            // year1semester: false,
            // year1semesters: [],
            // laterYear: false,
            // year2month: false,
            // year2months: [],
            // year2quarter: false,
            // year2quarters: [],
            // year2semester: false,
            // year2semesters: [],

            loading: true,
            exporting: false,
            importing: false,

            savedChanges: false,
            deletedChanges: false,
        }
    },

    computed: {
        disableDelete() {
            let disabled = false

            if (!this.hotInstance) {
                return disabled = true
            }

            if (!this.hotInstance.getSelected()) {
                return disabled = true
            }

            if (this.readOnly) {
                return disabled = true
            }

            if (this.hotInstance) {
                if (this.hotInstance.getSelected()) {
                    let selection = this.hotInstance.getSelected()
                    let firstRow = selection[0][0]
                    let lastRow = selection[0][2]
                    let length = this.objectData.length

                    let selectedRows = [firstRow, lastRow]
                    selectedRows.sort()

                    if (firstRow === length - 1 || lastRow === length - 1) {
                        disabled = true
                    }

                    if (firstRow !== lastRow) {
                        for (let i = selectedRows[0]; i >= selectedRows[1]; i--) {
                            if (!this.objectData[i].id_opex_planning) {
                                disabled = true
                            }
                        }
                    }
                }
            }

            return disabled
        },

        disableSave() {
            let disabled = false

            this.objectData.forEach((o, index) => {
                if (!o.opex_description && index !== this.objectData.length - 1) {
                    disabled = true
                }
            })

            if (this.readOnly) {
                disabled = true
            }

            return disabled
        }
    },  

    watch: {
        dialogChartAccountInput: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.setDateColumns()
                    this.getData()
                }          
            }
        },
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
    },

    updated() {
        if (this.$refs.hotTableChartAccountInput) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTableChartAccountInput.hotInstance
                this.hotInstance.render()
            }
        }
    },

    methods: {
        async getData() {
            this.loading = true
            this.data = []
            this.hotInstance = null

            this.selectedPurpose = this.purpose[0]
            this.selectedCostCenter = this.costCenter[0]

            let payload = {
                id_event: this.clickedRow.id_event,
                id_purpose: this.clickedRow.id_purpose,
                id_cost_center: this.clickedRow.id_cost_center,
                id_account_group_version: this.clickedRow.id_account_group_version,
                id_account_group: this.clickedRow.id_account_group,
            }

            try {
                const res = await this.$http.post(`${this.$ipOpexPlanning}opex-planning/list-detail`, { ...payload })
                if (res) {
                    this.columns = res.data.columns
                    this.headers = res.data.headers
                    this.data = res.data.rows

                    if (this.clickedRow.input_by_chart_account) {
                        this.getChartAccounts()
                    } else {
                        let accountCodeIndex = this.headers.findIndex(h => h.value === 'account_code')
                        this.headers[accountCodeIndex].hideColumn = true
                        this.headers[accountCodeIndex].options = false

                        let chartAccountIndex = this.headers.findIndex(h => h.value === 'chart_account_description')
                        this.headers[chartAccountIndex].hideColumn = true
                        this.headers[chartAccountIndex].options = false

                        return this.loadGrid()
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getChartAccounts() {
            try {
                let filter = {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_account_group",
                            operator: "=",
                            value: this.clickedRow.id_account_group
                        },
                        {
                            AndOr: "AND",
                            column: "id_chart_account_group_version",
                            operator: "=",
                            value: this.event.id_chart_account_group_version
                        },
                    ]
                }

                const res = await this.$http.post(`${this.$ipAccount}chart-account-group/list-options`, { filter })
                if (res) {
                    this.chartAccounts = res.data.rows
                    this.loadGrid()
                } else {
                    this.$toast.error('Nenhuma conta contábil encontrada')
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        loadGrid() {
            this.loading = true

            this.setColHeaders()
            this.setColumns()
            this.setHiddenColumns()
            this.setObjectData()

            this.loading = false
        },

        setDateColumns() {
            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()

            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })

            this.months = _months
            // this.year1months = this.months
            // this.year2months = this.months
            // this.year1quarters = this.quarters
            // this.year2quarters = this.quarters
            // this.year1semesters = this.semesters
            // this.year2semesters = this.semesters
        },

        setColHeaders() {
            let colHeaders = []

            this.headers.forEach((header) => {
                let headerIndex = parseInt(header.value.slice(5, 7), 10)
                let columnIndex = this.columns.findIndex(column => column.columnAlias === header.value)

                let headerText = header.text 
                if (this.columns[columnIndex].columnType === 'INT' || this.columns[columnIndex].columnType === 'DECIMAL') {
                    if (header.value === 'value00') {
                        let values = header.text.split(' ')
                        headerText = `${values[0]} ${values[1] || ''}`
                    }

                    let year = null
                    if (headerIndex > 0 && headerIndex <= 12) {
                        if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                            year = this.year1
                        } else {
                            year = this.year2
                        }
                        headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                    } else if (headerIndex >= 13 && headerIndex <= 24) {
                        if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                            year = this.year2
                        } else {
                            year = parseInt(this.year2, 10) + 1
                        }
                        headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                    }
                }

                colHeaders.push(headerText)
            })

            this.colHeaders = colHeaders
        },

        setColumns() {
            let columns = []

            this.headers.forEach(header => {
                let columnIndex = this.columns.findIndex(column => column.columnAlias === header.value)

                let readOnly = false
                if (header.editable === false) {
                    readOnly = true
                }

                let type = null
                let className = ''
                let numericFormat = null
                let source = null
                if (header.options) {
                    type = 'dropdown'
                    source = this.setSelectOptions()
                } else if (this.columns[columnIndex].columnType === 'INT' || this.columns[columnIndex].columnType === 'DECIMAL') {
                    type = 'numeric'
                    className += ' htRight'
                    numericFormat = {
                        pattern: {
                            thousandSeparated: true,
                            negative: "parenthesis",
                            mantissa: 2,
                        },
                        culture: ptBR.languageCode
                    }
                } else {
                    type = 'text'
                    className += ' htLeft' 
                }

                let hide = false
                if (header.hideColumn) {
                    hide = true
                }

                const totalColumns = [
                    'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                    'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                ]
                    
                if (totalColumns.includes(header.value)) {
                    className += ' border-sides-1 font-bold'
                }

                columns.push({ data: header.value, type, source, readOnly, className, numericFormat, hide, columnLetter: header.columnLetter })

            })

            this.columnsDialog = columns
        },

        setHiddenColumns() {
            let hiddenColumns = { columns: [], copyPasteEnabled: true }

            this.columnsDialog.forEach((column, index) => {
                if (column.hide) {
                    hiddenColumns.columns.push(index)
                }
            })

            this.hiddenColumns = hiddenColumns

            let cols = []

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth || !this.year1month) {
                    if (i < 10) {
                        col = this.columnsDialog.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columnsDialog.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth || !this.year2month) {
                    col = this.columnsDialog.findIndex(c => c.data === `value${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter || !this.year1quarter) {
                    col = this.columnsDialog.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter || !this.year2quarter) {
                    col = this.columnsDialog.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester || !this.year1semester) {
                    col = this.columnsDialog.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester || !this.year2semester) {
                    col = this.columnsDialog.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columnsDialog.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columnsDialog.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            this.hiddenColumns.columns.push(...cols)
        },

        setSelectOptions() {
            let selectOptions = []

            for (let i = 0; i < this.chartAccounts.length; i++) {
                selectOptions.push(this.chartAccounts[i].text)
            }

            return selectOptions
        },

        setObjectData() {
            this.objectData = []

            if (this.data.length) {
                this.data.forEach(row => {
                    for (let field in row) {
                        if (field.includes('value')) {
                            if (row[field]) {
                                row[field] = parseFloat(row[field])
                            }
                        }
                    }
                    this.objectData.push(row)
                })
            } else {
                let newRow = {}
                this.columnsDialog.forEach((column) => {
                    newRow[column.data] = null
                    if (column.type === 'numeric' && column.data.includes('year')) {
                        newRow[column.data] = this.generateDateFormulas(column, 1)
                    }
                })
    
                this.objectData.push(newRow)
            }

            this.insertTotalRow()
        },

        generateDateFormulas(c, index) {
            let i = index

            if (c.data === 'year1quarter1') {
                return `=F${i}+G${i}+H${i}`
            } else if (c.data === 'year1quarter2') {
                return `=J${i}+K${i}+L${i}`
            } else if (c.data === 'year1semester1') {
                return `=F${i}+G${i}+H${i}+J${i}+K${i}+L${i}`
            } else if (c.data === 'year1quarter3') {
                return `=O${i}+P${i}+Q${i}`
            } else if (c.data === 'year1quarter4') {
                return `=S${i}+T${i}+U${i}`
            } else if (c.data === 'year1semester2') {
                return `=O${i}+P${i}+Q${i}+S${i}+T${i}+U${i}`
            } else if (c.data === 'year1') {
                return `=F${i}+G${i}+H${i}+J${i}+K${i}+L${i}+O${i}+P${i}+Q${i}+S${i}+T${i}+U${i}`
            } else if (c.data === 'year2quarter1') {
                return `=Y${i}+Z${i}+AA${i}`
            } else if (c.data === 'year2quarter2') {
                return `=AC${i}+AD${i}+AE${i}`
            } else if (c.data === 'year2semester1') {
                return `=Y${i}+Z${i}+AA${i}+AC${i}+AD${i}+AE${i}`
            } else if (c.data === 'year2quarter3') {
                return `=AH${i}+AI${i}+AJ${i}`
            } else if (c.data === 'year2quarter4') {
                return `=AL${i}+AM${i}+AN${i}`
            } else if (c.data === 'year2semester2') {
                return `=AH${i}+AI${i}+AJ${i}+AL${i}+AM${i}+AN${i}`
            } else if (c.data === 'year2') {
                return `=Y${i}+Z${i}+AA${i}+AC${i}+AD${i}+AE${i}+AH${i}+AI${i}+AJ${i}+AL${i}+AM${i}+AN${i}`
            }
        },

        insertTotalRow() {
            let formulaRow = {}

            this.columnsDialog.forEach(column => {
                formulaRow[column.data] = null
                if (column.data === 'chart_account_description') formulaRow[column.data] = 'Total'
                if (column.type === 'numeric' && !column.hide) {
                    formulaRow[column.data] = this.generateTotalFormula(column)
                }
            })

            this.objectData.push(formulaRow)
        },

        generateTotalFormula(column) {
            let index = this.objectData.length
            let formula = `=`

            for (let i = 1; i <= index; i++) {
                formula += `+${column.columnLetter}${i}`
            }

            return formula
        },

        insertNewRow() {
            let newRow = {}
            let rowIndex = null

            if (this.objectData.length === 0) {
                rowIndex = 1
            } else {
                rowIndex = this.objectData.length
            }

            this.columnsDialog.forEach((column) => {
                newRow[column.data] = null
                if (column.type === 'numeric' && column.data.includes('year')) {
                    newRow[column.data] = this.generateDateFormulas(column, rowIndex)
                }
            })
            
            this.objectData.splice(this.objectData.length - 1, 1, newRow)
            this.insertTotalRow()

            this.$nextTick(() => {
                this.hotInstance.loadData(this.objectData)
                this.showMonth(1)
                this.showMonth(2)
                this.showQuarter(1)
                this.showQuarter(2)
                this.showSemester(1)
                this.showSemester(2)
                this.showYear(1)
                this.showYear(2)
            })
        },

        async deleteSelectedRow() {
            if (this.selectedRow[0][0] === this.selectedRow[0][2]) {
                let rowIndex = this.selectedRow[0][0]
                let selectedRow = this.objectData[rowIndex]
                let selectedRowId = selectedRow.id_opex_planning

                if (selectedRowId) {
                    try {
                        const res = await this.$http.delete(`${this.$ipOpexPlanning}opex-planning/delete/${selectedRowId}`, {})
                        if (res) {
                            this.$toast.success('Conta contábil deletada com sucesso!')

                            this.objectData.splice(rowIndex, 1)
                            this.objectData.pop()
                            this.insertTotalRow()
                        }
                    } catch (err) {
                        this.$fnError(err)
                    }
                } else {
                    this.objectData.splice(rowIndex, 1)
                    this.objectData.pop()
                    this.insertTotalRow()
                }
            } else {
                let items = []
                let selection = [this.selectedRow[0][0], this.selectedRow[0][2]]
    
                selection.sort()
                let firstRow = selection[0]
                let lastRow = selection[1]

                for (let i = lastRow; i >= firstRow; i--) {
                    if (this.objectData[i].id_opex_planning) {
                        items.push({ id_opex_planning: this.objectData[i].id_opex_planning })
                    }
                }

                if (items.length) {
                    let payload = {
                        delete: { items }
                    }
    
                    try {
                        const res = await this.$http.post(`${this.$ipOpexPlanning}opex-planning/mass-effect`, payload)
                        if (res) {
                            this.$toast.success('Contas contábeis deletadas com sucesso!')
    
                           
                        }
                    } catch (err) {
                        this.$fnError(err)
                    }
                }

                for (let i = lastRow; i >= firstRow; i--) {
                    this.objectData.splice(i, 1)
                }

                this.objectData.pop()
                this.insertTotalRow()
            }

            this.deletedChanges = true
            
            this.$nextTick(() => {
                this.hotInstance.loadData(this.objectData)
                if (this.objectData.length === 1) {
                    this.insertNewRow()
                }
            })
        },

        async showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                await this.$emit('setYearMonth', { year, value: !this.year1month })

                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    await this.$emit('setYearMonths', { year, value: this.months })
                    plugin.showColumns(cols)
                } else {
                    await this.$emit('setYearMonths', { year, value: [] })
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                await this.$emit('setYearMonth', { year, value: !this.year2month })

                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    await this.$emit('setYearMonths', { year, value: this.months })
                    plugin.showColumns(cols)
                } else {
                    await this.$emit('setYearMonths', { year, value: [] })
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        async showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }

            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }
            
            this.hotInstance.render()
        },

        async showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                await this.$emit('setYearQuarter', { year, value: !this.year1quarter })
            
                if (this.year1quarter) {
                    await this.$emit('setYearQuarters', { year, value: this.quarters })
                    plugin.showColumns(cols)
                } else {
                    await this.$emit('setYearQuarters', { year, value: [] })
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                await this.$emit('setYearQuarter', { year, value: !this.year2quarter })

                if (this.year2quarter) {
                    await this.$emit('setYearQuarters', { year, value: this.quarters })
                    plugin.showColumns(cols)
                } else {
                    await this.$emit('setYearQuarters', { year, value: [] })
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }
            
            this.hotInstance.render()
        },

        async showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                await this.$emit('setYearSemester', { year, value: !this.year1semester })

                if (this.year1semester) {
                    await this.$emit('setYearSemesters', { year, value: this.semesters })
                    plugin.showColumns(cols)
                } else {
                    await this.$emit('setYearSemesters', { year, value: [] })
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                await this.$emit('setYearSemester', { year, value: !this.year2semester })

                if (this.year2semester) {
                    await this.$emit('setYearSemesters', { year, value: this.semesters })
                    plugin.showColumns(cols)
                } else {
                    await this.$emit('setYearSemesters', { year, value: [] })
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }
            
            this.hotInstance.render()
        },

        async showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                await this.$emit('setYear', { year, value: !this.startingYear })

                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                await this.$emit('setYear', { year, value: !this.laterYear })

                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        closeChartAccountInputDialog() {
            this.newRows = 0
            this.hotInstance = null

            if (this.deletedChanges) {
                this.savedChanges = true
            }

            this.$emit('closeChartAccountInputDialog', this.savedChanges)
        },

        async confirmChartAccountInputDialog() {
            let payload = { items: [] }

            this.editedRows.forEach(row => {
                this.objectData[row].id_event = this.event.id
                this.objectData[row].id_purpose = this.selectedPurpose.id
                this.objectData[row].id_cost_center = this.selectedCostCenter.id_cost_center
                this.objectData[row].id_account_group = this.clickedRow.id_account_group
                this.objectData[row].id_account_group_version = this.event.id_account_group_version
                this.objectData[row].id_chart_account = this.clickedRow.input_by_chart_account ? this.objectData[row].id_chart_account : null

                payload.items.push(this.objectData[row])
            })

            try {
                const res = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/mass-save', { ...payload })
                if (res) {
                    this.saving = false
                    this.savedChanges = true
                    this.$toast.success(res.data.msg)
                    this.editedRows = []
                    return this.getData()
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }
        },

        async exportSheet(){
            this.exporting = true

            let payload = {
                id_event: this.clickedRow.id_event,
                id_purpose: this.clickedRow.id_purpose,
                id_cost_center: this.clickedRow.id_cost_center,
                id_account_group_version: this.clickedRow.id_account_group_version,
                id_account_group: this.clickedRow.id_account_group,
                queryType: 'SELECT_DETAIL'
                // filter: { conditions: [
                //     {
                //         AndOr: "AND",
                //         column: "id_account_group",
                //         operator: "=",
                //         value: this.clickedRow.id_account_group
                //     }
                // ]}
            }

            try {
                const res = await this.$http.post(this.$ipOpexPlanning + 'opex-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
                body.append('queryType', 'SELECT_DETAIL')
    
                try {
                    const uploadRes = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        const massEffectRes = await this.$http.post(this.$ipOpexPlanning + 'opex-planning' + '/mass-effect', { ...uploadRes.data.id })
                        if (massEffectRes) {
                            this.$toast.success(massEffectRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.getData()
                        }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },
    }
}
</script>

<style scoped>
</style>
